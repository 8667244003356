<template>
    <div>
        <template v-if="loading">
            <div class="mt-base text-center">
                <font-awesome-icon :icon="loadingIcon" size="3x" class="mb-5" spin />
                <br/>Loading your organisations.. Please wait..
            </div>
        </template>
        <template v-else>
            <div class="logo mb-3 text-center mt-base">
                <img :src="require('@/assets/images/logo/logo-dark.png')" alt="logo" style="max-width: 150px; max-height: 75px;">
            </div>
            <h4 class="mt-3 mb-2 text-center">
                Choose Organisation to continue
            </h4>
            <div class="text-center">
                or <br/>
                <router-link :to="{name: 'organisation-new'}">
                    <vs-button type="flat" color="primary">Create a New Organisation</vs-button>
                </router-link>
            </div>
            <vs-divider />
            <div class="mx-auto w-full px-5 md:w-3/4 mt-base">
                <vx-card v-if="organisations.length == 0">
                    <div class="text-center">
                        You're not part of any organisation, join one or create a new Organisation.
                    </div>
                </vx-card>
                <vx-card
                    v-for="(organisation, index) in organisations"
                    :key="index"
                    @click="load_organisation(organisation)"
                    class="mb-3"
                >
                    <div class="flex items-center">
                        <div class="flex-1">
                            {{ organisation.name }}
                            <small class="block">{{ organisation.user ? format_role(organisation.user.role) : 'No Access' }}</small>
                        </div>
                        <div class="flex items-center">
                            <vs-button class="px-2 py-1 round" color="primary" @click="load_organisation(organisation)">
                                <font-awesome-icon :icon="rightArrowIcon" />
                            </vs-button>
                        </div>
                    </div>

                </vx-card>
            </div>

            <div class="text-center my-base">
                <vs-divider />
                <router-link :to="{name: 'page-logout', query: {next: $route.fullPath}}">
                    <vs-button type="flat" color="primary">Logout</vs-button>
                </router-link>
            </div>
        </template>
    </div>
</template>

<script>
import { faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Request } from '../../utils/requests'

export default {
    data() {
        return  {
            organisations: [],
            loading: true,

            roles: [
                {label: 'Admin', value: 'a'},
                {label: 'Staff', value: 's'},
                {label: 'Client', value: 'c'},
            ]
        }
    },
    computed: {
        loadingIcon: () => faSpinner,
        rightArrowIcon: () => faChevronRight
    },
    mounted() {
        this.load_organisations()
    },
    methods: {
        load_organisations() {
            this.loading = true
            Request(this, 'GET', this.$store.state.apiendpoints.user_organisations, {limit: 100}).then(
                async res => {
                    this.organisations = res.results
                    await this.load_last_organisation()
                    this.loading = false
                },
                () => {
                    this.$vs.notify({
                        time: 60000,
                        title: 'Error',
                        text: "Unable to load organisations. Please refresh to try again.",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }
            )
        },
        load_last_organisation() {
            return new Promise(async (resolve, reject) => {
                if (this.$route.query.force) {
                    return resolve()
                }
                let last_organisation = this.$store.getters['organisation/lastOrganisation']
                let organisation = null
                if (last_organisation != null) {
                    organisation = this.organisations.find(v => v.id == last_organisation.id)
                }

                if (!organisation) {
                    if (this.organisations.length == 1) {
                        organisation = this.organisations[0]
                    } else {
                        return resolve()
                    }
                }

                this.load_organisation(organisation)
            })
        },
        load_organisation(organisation) {
            this.$store.dispatch('organisation/updateActiveOrganisation', organisation)
            this.$store.dispatch('organisation/updateOrganisationUser', organisation.user)
            this.$router.push(this.$route.query.next || "/")
        },
        format_role(role) {
            let _role =  this.roles.find(v => v.value == role)
            if (!_role) return "Unknown"
            return _role.label
        }
    }
}
</script>
